/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import constants from "../styles/constants"
import Header from "./header"

const Layout = ({
  children,
  activeSectionSlug,
  allowClickAnyway,
  tan = false,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={{ background: constants.bodyBG }}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        activeSectionSlug={activeSectionSlug}
        allowClickAnyway={allowClickAnyway}
      />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main
          style={{
            padding: "2rem",
            background: tan ? "transparent" : "#fff",
            boxShadow: tan ? null : constants.simpleShadow,
          }}
        >
          {children}
        </main>
        <footer style={{ fontSize: "70%" }}>
          © {new Date().getFullYear()} &bull;{" "}
          <a href="mailto:art@moniqueaguerre.com">Contact me</a>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  activeSectionSlug: PropTypes.string,
  allowClickAnyway: PropTypes.bool,
  tan: PropTypes.bool,
}
Layout.defaultProps = {
  allowClickAnyway: true,
}
export default Layout
