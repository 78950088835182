import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import constants from "../styles/constants"
import logo from "../assets/logo.svg"
import Nav from "./nav"

const Header = ({ siteTitle, activeSectionSlug, allowClickAnyway }) => (
  <header
    style={{
      marginBottom: `2rem`,
      borderBottom: `1px solid ${constants.mediumGray}`,
      boxShadow: `0 0 10px ${constants.mediumGray}`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        height: 90,
        padding: `1.45rem 1.0875rem`,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img src={logo} alt={siteTitle} style={{ height: 30 }} />
        </Link>
      </h1>
      <Nav
        activeSectionSlug={activeSectionSlug}
        allowClickAnyway={allowClickAnyway}
      />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  activeSectionSlug: PropTypes.string,
  allowClickAnyway: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
