import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import aboutImage from "../assets/at-the-hub.jpg"

const AboutPage = () => (
  <Layout activeSectionSlug="about">
    <SEO title="About" />
    <h1>About</h1>

    <p>
      <img
        src={aboutImage}
        style={{
          float: "right",
          width: "50%",
          margin: "0 0 1rem 1rem",
        }}
        alt="Monique with her painting"
      />
      In making art I strive to create an atmosphere, a mood, a space for
      contemplation. I am captivated by the way art can transport us into
      different states of mind. It is a mysterious process that is different for
      each person. Art does not exist in a vacuum – it is part of an
      environment, interacting with the light and energy of its surroundings and
      the consciousness of the people who witness it, which changes from moment
      to moment. I see my paintings as invitations into the present moment, a
      place of wonder and mystery.
    </p>

    <p>
      I have always been drawn to mythic and mystical imagery. My studies in
      Depth Psychology furthered my exploration of the dreams, symbols, and
      archetypes that are woven into the psychic fabric of every human being. In
      my current series of paintings I have been focusing on the symbol of the
      mandala. It is a universal image of wholeness, integration, and harmony.
      Mandalas have been used as tools for centering the mind and healing the
      psyche. I am exploring ways to incorporate this ancient image into my own
      contemporary expression. It is my wish that these mandalas will bring a
      sense of peace and beauty to whatever space they reside in.
    </p>

    <p>
      While I refer to these works as paintings, they are actually three
      dimensional explorations of texture. I incorporate different materials
      such as fabric, lace, acrylic mediums, glass, and mirrors. The use of
      mirrors has both aesthetic and symbolic appeal for me. I love the way they
      catch the light, sparkling and shifting, making the painting seem alive.
      Mirrors also evoke the mind’s capacity to reflect upon life and the self,
      and our ability as human beings to reflect each other in relationship.
    </p>
    <p>
      I feel that creating art is a direct extension of the creative force of
      nature which continuously unfolds in every moment. I wish to serve this
      process and allow it to express itself through me. I paint with the
      intention of bringing more beauty, peace, and harmony into the world.
    </p>

    <h2>Artist Bio</h2>

    <p>
      Born and raised in Marin County, California, Monique Aguerre’s training in
      art began in childhood, and continued through her studies at the
      California College of Arts and Crafts. Her work is inspired by mythology,
      nature, and the unconscious realm of the human psyche. As a graduate
      student of depth psychology, Monique began to explore the art of mask
      making and its role in the expression of the human psyche. She went on to
      start her own mask making business called Mythica Masks. A selection of
      her work can be viewed at{" "}
      <a
        href="https://mythicamasks.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Mythica Masks
      </a>
      .
    </p>
    <p>
      Monique has recently transitioned to painting in acrylics and mixed-media.
      She is currently focusing on a series of mandalas inspired by sacred
      symbols and architecture. She continues to seek out new techniques and
      materials with which to express her fascination with consciousness,
      mythology, and the divine. Monique holds a master’s degree in depth
      psychology from Sonoma State University.
    </p>

    <p>
      <a href="mailto:art@moniqueaguerre.com">
        <strong>Email me</strong>
      </a>
    </p>
  </Layout>
)

export default AboutPage
