import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import constants from "../styles/constants"

const NavLink = ({ active, slug, title, allowClickAnyway }) => (
  <span
    style={{
      marginLeft: "0.5rem",
      fontSize: "60%",
      textTransform: "uppercase",
      color: active ? constants.mediumGray : constants.darkishGray,
    }}
  >
    {active ? (
      allowClickAnyway ? (
        <Link to={`/${slug}`} style={{ color: "inherit" }}>
          {title}
        </Link>
      ) : (
        title
      )
    ) : (
      <Link to={`/${slug}`}>{title}</Link>
    )}
  </span>
)

const Nav = ({ activeSectionSlug, allowClickAnyway }) => {
  // grab the list of sections, which is set in gatsby-config
  const data = useStaticQuery(graphql`
    query NaviQuery {
      site {
        siteMetadata {
          sections {
            slug
            title
          }
        }
      }
    }
  `)
  const { sections } = data.site.siteMetadata
  return (
    <nav>
      <NavLink
        slug="about"
        title="About"
        active={"about" === activeSectionSlug}
      />

      {sections.map(({ slug, title }) => (
        <NavLink
          key={title}
          active={slug === activeSectionSlug}
          allowClickAnyway={allowClickAnyway}
          slug={slug}
          title={title}
        />
      ))}
    </nav>
  )
}
Nav.propTypes = {
  activeSectionSlug: PropTypes.string,
  allowClickAnyway: PropTypes.bool,
}

export default Nav
